import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import ENDPOINT from '../constants/api-endpoints';
import { ProgressBar } from 'react-loader-spinner'
var parse = require('html-react-parser');

const Page = () => {
    let { slug } = useParams();
    const [pageData, setPage] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.PAGE_DATA + '/' + slug)
            .then((response) => response.json())
            .then((responseData) => {
                setPage(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    let pageTitle = '';
    let pageContent = '';
    if (pageData.length !== 0) {
        pageTitle = pageData.data.title;
        pageContent = pageData.data.content;
        if (!pageContent.data) {
            return (
                <section className="page-sec">
                    <section className="container">
                        <div className="innerpage">
                            <h1>{pageTitle}</h1>
                            {parse(pageContent)} 
                        </div>
                    </section>
                </section>
            );
        } else {
            return (
                <section className="page-sec">
                    <section className="container">
                        <div className="innerpage">
                            <center>
                            <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{textAlign: 'center', justifyContent: 'center'}}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor = '#f8de00'
                                    barColor = '#570101'
                                />
                            </center>
                        </div>
                    </section>
                </section>
            );
        }
        
    } else {
        return (
            <section className="page-sec">
                <section className="container">
                    <div className="innerpage">
                        <center>
                        <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{textAlign: 'center', justifyContent: 'center'}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#f8de00'
                                barColor = '#570101'
                            />
                        </center>
                    </div>
                </section>
            </section>
        );
    }
};

export default Page;
