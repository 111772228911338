import React, { useState, useEffect } from 'react';

import ENDPOINT from '../constants/api-endpoints';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Banner = () => {
    const [bannerData, setPosts] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.BANNER_LIST)
            .then((response) => response.json())
            .then((responseData) => {
                setPosts(responseData.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    return (
        <section className="container-fluid banner_block">
            <div className="row">
                <div className="col-4 col-sm-4">
                    <div className="big-logo">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/big_logo.jpg`} alt=""/>
                    </div>
                </div>
                <div className="col-8 col-sm-8">
                    <div className='slider-sec'>
                        <OwlCarousel className='owl-theme' nav loop items={1}>
                            {bannerData.map((item, i) => {
                                    return (
                                        <div className="item" key={i}>
                                            <img src={item.imageLink} alt={item.title} />
                                        </div>
                                    );
                            })}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;
