
import React, { useState, useEffect } from 'react';
import ENDPOINT from '../constants/api-endpoints';

function FooterNavigation(props) {
	const currentYear = new Date().getFullYear();
	const goNav = (goto) => {
        window.location.href = goto;
    };

	function ListItem(props) {
		const value = props.value;
		return (
			<li>
				<a href="#" onClick={() => goNav(value.url)}>{value.title}</a>
			</li>
		);
	}
	
    const [menuData, setMenus] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.NAV_MENU)
            .then((response) => response.json())
            .then((responseData) => {
                setMenus(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const mData = menuData;
    let listItems = '';
    if (mData.length !== 0) {
        const fNav = mData.Footer;
        listItems = fNav.map((item) =>
            <ListItem value={item} key={item.id} />
        );
    }
	const Address = () => {
        const [addressData, setAddress] = useState([]);
        useEffect(() => {
            fetch(ENDPOINT.ADDRESS_DATA)
                .then((response) => response.json())
                .then((responseData) => {
                    setAddress(responseData.data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }, []);
        return (
            <>
                {addressData.map((item, i) => {
                    return (
						<div className="address" key={"div" + i} >
								<p key={"p1"+ i}><i className="fa fa-map-marker" aria-hidden="true" key={"i1" + i}></i> <strong key={"s1" + i}>{item.country}:</strong></p>
								<p key={"p2" + i}>{item.addressLine2}<br/>
								{item.addressLine1}<br/>
								{item.city}, {item.state}-{item.pincode}<br/>
								Email :  {item.email}</p>
						</div>
                    );
                })}
            </>
        );
    }
    return (
        <footer>
            <div className="container">
                    <div className="row">
                            <div className="col-sm-3">
                                    <h3>Important Links</h3>
                                    <ul className="footer-links">
                                        {listItems}
                                    </ul>
                            </div>
                            <div className="col-sm-6">
                                    <h3>Our Adresses</h3>           
                                    <div className="address-sec">
                                        <Address />
                                    </div>
                            </div>
                            <div className="col-sm-3">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/footer_logo1.png`} alt=""/><br/><br/>
                            <img src={`${process.env.PUBLIC_URL}/assets/img/footer_logo2.png`} alt=""/>
                            </div>
                    </div>
                    <div className="copyright">
                            <p>Copyright &copy; {currentYear}. ASF Group  All rights reserved</p>
                    </div>
            </div>
        </footer> 
    );
}

export default FooterNavigation;
