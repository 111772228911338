import MenuItems from './MenuItems';
const Dropdown = ({ submenus, dropdown, depthLevel, isNavExpanded }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';

  //Responsive Settings
  let $browserWidth = window.innerWidth || document.documentElement.clientWidth;
  let subMenusUlClass = ($browserWidth < 768 ) ? 'to-left-ul' : '';
  let responseClass = dropdown ? 'show' : '';
  if ($browserWidth < 768 ) {
    responseClass = isNavExpanded ? 'open' : 'close';
  } 
  return (
    <ul
      className={` 
      ${($browserWidth < 768 ) ? 'menu__sub-menu' : 'dropdown menu__sub-menu__item'}
      ${dropdownClass} 
      ${subMenusUlClass} 
      ${responseClass}
      `}
    >
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
