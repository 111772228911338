import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Banner from '../component/Banner';
import React, { useState, useEffect } from 'react';
import ENDPOINT from '../constants/api-endpoints';
var parse = require('html-react-parser');

const AboutUsBlock = (props) => {
    const value = props.value;
    return (
        <section className="container about-sec text-center">
            {parse(value.content)}
        </section>
    );
}

const EclipseBlock = (props) => {
    const value = props.value;
    return (
        <>
            <h2 className="title">{value.heading}</h2>
            <div className="row">
                {value.data.map((item, i) => {
                    return (
                        <div className="col-sm-3" key={i}>
                            {parse(item.content)}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

const TestingProcedureBlock = (props) => {
    const value = props.value;
    return parse(value.content);
}

const ImageGalleryBlock = (props) => {
    const value = props.value;
    return (
        <>
            <OwlCarousel id="pictures" className="thumb-slider owl-carousel owl-theme" responsive={{0:{items:1},600:{items:3},1000:{items:6}}} nav loop mergeFit items={6} margin={10} autoplay>
                {value.map((item, i) => {
                    return (
                        <img src={item.thumbnail} width="140" height="150" alt="title" key={i} />
                    );
                })}
            </OwlCarousel>
        </>
    );
}

const EventBlock = (props) => {
    const value = props.value;
    return (
        <>
            <h2>{value.heading}</h2>
            <div className="scrollItem" style={{ position: 'relative', height: '185px', overflow: 'hidden' }}>
                <ul style={{ margin: '0px', position: 'absolute', top: '0px' }}>
                    {value.data.map((item, i) => {
                        return (
                            <li key={i}>
                                <a href="#">
                                    <div className="thumb">
                                        {parse(item.content)}
                                    </div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

const Homepage = () => {
	const [homepageData, setHomepage] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.HOMEPAGE_DATA)
            .then((response) => response.json())
            .then((responseData) => {
                setHomepage(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const hpData = homepageData;
    let aboutUsBlock = '';
    if (hpData.aboutUsBlock) {
        aboutUsBlock = <AboutUsBlock value={hpData.aboutUsBlock} />;
    }
    let eclipseBlock = '';
    if (hpData.technicalFinishesBlock) {
        eclipseBlock = <EclipseBlock value={hpData.technicalFinishesBlock} />;
    }
    let testingProcedureBlock = '';
    if (hpData.testingProcedureBlock) {
        testingProcedureBlock = <TestingProcedureBlock value={hpData.testingProcedureBlock} />;
    }
    let imageGalleryBlock = '';
    if (hpData.gelleryImages) {
        imageGalleryBlock = <ImageGalleryBlock value={hpData.gelleryImages} />;
    }
    let eventBlock = '';
    if (hpData.eventList) {
        eventBlock = <EventBlock value={hpData.eventList} />;
    }
    return (
        <section className="page-sec">
            <Banner />
            {aboutUsBlock}
            <section className="container-fluid main-thumb-sec">
                {eclipseBlock}
                <div className="row">
                    <div className="readmore-sec">
                        {testingProcedureBlock}
                    </div>
                </div>
            </section>
            <section className="btmgry-block">
                <div className="container">
                    <div className="thumbnail-slider">
                        {imageGalleryBlock}
                    </div>
                    <div className="eventlist">
                        {eventBlock}
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Homepage;
