const localSettings = {
  API_END_POINT_URL: 'http://asf.lndo.site'
};
  
const devSettings = {
  API_END_POINT_URL: 'https://asf.mybetasite.net'
};

const prodSettings = {
  API_END_POINT_URL: 'https://webadmin.asfgroup.com'
};

const prepareSettings = () => {
	let settings;
	switch (document.location.hostname) {
		case 'asfgroup.com':
			settings = prodSettings;
			break;
		case 'asfapp.mybetasite.net':
			settings = devSettings;
			break;
		case 'asf.lndo.site':
		default:
			settings = localSettings;
	}
	return settings;
};
  
export default prepareSettings();
