import React, { useState, useEffect } from 'react';
import ENDPOINT from '../constants/api-endpoints';
import { ProgressBar } from 'react-loader-spinner'
var parse = require('html-react-parser');
const Event = () => {
    const [homepageData, setHomepage] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.HOMEPAGE_DATA)
            .then((response) => response.json())
            .then((responseData) => {
                setHomepage(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
	let eList = (homepageData.eventList) ? homepageData.eventList : [];
    if (eList.length !== 0){
        if (eList.heading) {
            return (
                <section className="page-sec">
                    <section className="container">
                        <div className="innerpage">
                            <h1>{eList.heading}</h1>
                            <div className=" eventlist">
                                <div className="scrollItem">
                                    <ul>
                                        {eList.data.map((item, i) => {
                                            return (
                                                <li key={i}>
                                                    <a href="javascript:void">
                                                        <div className="thumb">
                                                            {parse(item.content)}
                                                        </div>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            ); 
        } else {
            return (
                <section className="page-sec">
                    <section className="container">
                        <div className="innerpage">
                            <center>
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#f8de00'
                                barColor = '#570101'
                            />
                            </center>
                        </div>
                    </section>
                </section>
            );
        }
    } else {
        return (
            <section className="page-sec">
                <section className="container">
                    <div className="innerpage">
                        <center>
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#f8de00'
                                barColor = '#570101'
                            />
                        </center>
                    </div>
                </section>
            </section>
        );
    }
};

export default Event;
