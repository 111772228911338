import Settings from '../constants/settings';
const apiEndPoint = Settings.API_END_POINT_URL || {};
const apiUrls = {
    BASE_PATH: `${apiEndPoint}`,
    HOMEPAGE_DATA: `${apiEndPoint}/api/v1/homepageData`,
    BANNER_LIST: `${apiEndPoint}/api/v1/bannerList`,
    NAV_MENU: `${apiEndPoint}/api/v1/navigationMenu`,
    PAGE_DATA: `${apiEndPoint}/api/v1/pageData`,
    CONTACT_SUBMIT: `${apiEndPoint}/api/v1/contactSubmit`,
    ADDRESS_DATA: `${apiEndPoint}/api/v1/addressData`,
    META_DATA: `${apiEndPoint}/api/v1/metaData`
};
export default apiUrls;
