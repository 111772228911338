import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import React from 'react';
import Homepage from "./container/Homepage";
import Event from "./container/Event";
import Page from "./container/Page";
import Contact from "./container/Contact";
import MetaData from './component/MetaData';
import HeaderNavigation from './component/HeaderNavigation';
import FooterNavigation from './component/FooterNavigation';

function Skeleton() {
  return (
    <>
		<Router>
			<MetaData />
			<HeaderNavigation />
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route exact path="/events" element={<Event />} />
				<Route path="/page/:slug" element={<Page />} />
				<Route exact path="/contact" element={<Contact />} />
			</Routes>
			<FooterNavigation />
		</Router>
    </>
  );
}

export default Skeleton;
