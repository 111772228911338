
import React, { useState, useEffect } from 'react';
import ENDPOINT from '../constants/api-endpoints';
import MenuItems from './MenuItems';

export default function  HeaderNavigation() {
    const [menuData, setMenus] = useState([]);
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    useEffect(() => {
        fetch(ENDPOINT.NAV_MENU)
            .then((response) => response.json())
            .then((responseData) => {
                setMenus(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const mData = menuData;
    let listItems = [];
    if (mData.length !== 0) {
        const hNav = mData.Header;
        listItems = hNav;
    }
    //Responsive Settings
    let $browserWidth = window.innerWidth || document.documentElement.clientWidth;
    let menuContent = ($browserWidth < 768 ) ? 'close' : '';
    let burgerClass = '';
    let menusUlClass = ($browserWidth < 768 ) ? 'to-left-ul' : 'menus';
    if (isNavExpanded) {
        menuContent = 'open';
        burgerClass = 'is-active';
    }
    return (
        <header id="header">
            <div className="col-sm-12">
                    <div className="logo">
                        <a href="/">
                            <img 
                                src={`${process.env.PUBLIC_URL}/assets/img/logo.png`}
                                alt="" 
                            />
                        </a>
                    </div>
                    <div className="navigation">
                        <div className={"menu-content overlay " + menuContent} >
                                <nav className="menu">
                                    <ul className={menusUlClass} >
                                        {listItems.map((menu, index) => {
                                        const depthLevel = 0;
                                            return (
                                                <MenuItems
                                                    items={menu}
                                                    key={index}
                                                    depthLevel={depthLevel}
                                                />
                                            );
                                        })}
                                    </ul>
                                </nav>
                        </div>
                        <div id="nav-icon" onClick={() => {
                                setIsNavExpanded(!isNavExpanded);
                                }} className={"menu-button visible-xs visible-sm " + burgerClass} >
                            <span className="burger-icon"></span>
                        </div>
                    </div>
                    <div className="rightlogo"><img src={`${process.env.PUBLIC_URL}/assets/img/eclipse-logo.png`} alt=""/></div>
            </div>
            <div className="clearfix"></div>
        </header>
    );
}
