import React, { useEffect,useState } from 'react';
import ENDPOINT from '../constants/api-endpoints';
// Bootstrap CSS
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { ProgressBar } from 'react-loader-spinner'
var parse = require('html-react-parser');

const Contact = () => {
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({site:1});
    const [resMsg, setResMsg] = useState('');
    const [showModal, setShow] = useState({ show: false, data: null });
    const handleClose = () => setShow({ show: false, data: null });
    const handleShow = (map) => {
        setShow({ show: true, data: map })
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            fetch(ENDPOINT.CONTACT_SUBMIT, {
                method: 'POST',
                body: JSON.stringify({ formData }),
                headers: { 'Content-Type': 'application/json' },
            })
            .then(response => response.json())
            .then((responseData) => {
                if (responseData.status === 'success') {
                    setResMsg(responseData.message);
                    setFormData([]);
                } else {
                    setResMsg(responseData.message);
                    setFormData([]);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
        }
        setValidated(true);
    };
    const Address = () => {
        const [addressData, setAddress] = useState([]);
        useEffect(() => {
            fetch(ENDPOINT.ADDRESS_DATA)
                .then((response) => response.json())
                .then((responseData) => {
                    setAddress(responseData.data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }, []);
        return (
            <>
                {addressData.map((item, i) => {
                    return (
                        <>
                            <div className="address">
                                <p style={{cursor: 'pointer'}} title="Click to view the Map" onClick={() => handleShow(item.map)} data-target="#myModal{i}"><i className="fa fa-map-marker" aria-hidden="true"></i> <strong>{item.country}:</strong></p>
                                <p>{item.addressLine2}<br/>
                                {item.addressLine1}<br/>
                                {item.city}, {item.state}-{item.pincode}</p>
                                <p>Email : {item.email}</p>
                            </div>
                        </>
                    );
                })}
            </>
        );
    }
    let modelMap = <ProgressBar height="80" width="80" ariaLabel="progress-bar-loading" wrapperStyle={{}} wrapperClass="progress-bar-wrapper" borderColor = '#f8de00' barColor = '#570101' />;
    if (showModal.data) {
        modelMap = parse(showModal.data);
    }
    return (
        <section className="page-sec">
            <section className="container">
                <div className="innerpage">
                    <h1>Contact Us</h1>
                    <div className="row">
                        <div className="col-sm-6 cntPge">
                            <Address />

                            <Modal show={showModal.show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                <Modal.Title>Address on Map</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {modelMap}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div className="col-sm-6">
                            <div className="contact_form">
                                <h2>Send Us a Message</h2>
                                <p id='resMsg'>{resMsg}</p>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formName">
                                        <Form.Control 
                                            required 
                                            type="text" 
                                            id="name" 
                                            controlId="formName" 
                                            name="name" 
                                            placeholder="Enter Name"
                                            onChange={e => setFormData({ ...formData, name: e.target.value })}
                                            />
                                        <Form.Control.Feedback type="invalid" style={{color: '#f6f6f6'}}>
                                            Please enter name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Control 
                                            required 
                                            type="email" 
                                            id="email" 
                                            controlId="formEmail" 
                                            name="email" 
                                            placeholder="Enter Email" 
                                            onChange={e => setFormData({ ...formData, email: e.target.value })}
                                            />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formMessage">
                                        <Form.Control 
                                            required 
                                            as="textarea" 
                                            rows={4} 
                                            id="message" 
                                            controlId="formMessage" 
                                            name="message" 
                                            placeholder="Your Message"
                                            onChange={e => setFormData({ ...formData, message: e.target.value })}
                                             />
                                        <Form.Control.Feedback type="invalid">
                                            Please put your message.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button variant="warning" className="btn submitbutt pull-right" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>       
                </div>
            </section>
        </section>
    );
};

export default Contact;